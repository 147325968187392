import React from "react";
import Layout2 from "../components/layout2";
import SEO from "../components/seo";

const Impressum = ({ data }) => {
  return (
    <Layout2 headerClass="bg-white relative">
      <SEO title="Die Praxis" />

      <div className="min-h-screen pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 mb-20 bg-size-5/6 md:bg-size-4/5 lg:bg-size-2/3 bg-right-top bg-no-repeat flex flex-col items-center">
        <div className="container w-3/4 md:w-1/2 mt-20  self-start">
          <h1 className="text-3xl md:text-5xl text-brand3 leading-tight">
            Impressum
          </h1>
          <div className="mt-12">
            <h2 className="font-bold">Unternehmen</h2>
            <p>
              Firma: Praxis am Lägern
              <br />
              Adresse: Kirchweg 20, 5420 Ehrendingen
              <br />
              Telefon: +41562210023
              <br />
              Email: info@praxis-am-laegern.ch
            </p>
          </div>
          <div className="mt-12">
            <h2 className="font-bold">Datenschutzerklärung</h2>
            <h3 className="font-semibold mt-4">Grundlegendes</h3>
            <p>
              Diese Datenschutzerklärung soll die Nutzer dieser Website über die
              Art, den Umfang und den Zweck der Erhebung und Verwendung
              personenbezogener Daten durch den Websitebetreiber [Ihre
              Kontaktdaten einfügen] informieren. Der Websitebetreiber nimmt
              Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen
              Daten vertraulich und entsprechend der gesetzlichen Vorschriften.
              Da durch neue Technologien und die ständige Weiterentwicklung
              dieser Webseite Änderungen an dieser Datenschutzerklärung
              vorgenommen werden können, empfehlen wir Ihnen sich die
              Datenschutzerklärung in regelmäßigen Abständen wieder
              durchzulesen. Definitionen der verwendeten Begriffe (z.B.
              “personenbezogene Daten” oder “Verarbeitung”) finden Sie in Art. 4
              DSGVO.
            </p>
            <h3 className="font-semibold mt-4"> Zugriffsdaten</h3>
            <p>
              Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund
              unseres berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO)
              Daten über Zugriffe auf die Website und speichern diese als
              „Server-Logfiles“ auf dem Server der Website ab. Folgende Daten
              werden so protokolliert:{" "}
            </p>
            <p>
              - Besuchte Website - Uhrzeit zum Zeitpunkt des Zugriffes - Menge
              der gesendeten Daten in Byte - Quelle/Verweis, von welchem Sie auf
              die Seite gelangten - Verwendeter Browser - Verwendetes
              Betriebssystem - Verwendete IP-Adresse Die Server-Logfiles werden
              für maximal 7 Tage gespeichert und anschließend gelöscht. Die
              Speicherung der Daten erfolgt aus Sicherheitsgründen, um z. B.
              Missbrauchsfälle aufklären zu können. Müssen Daten aus
              Beweisgründen aufgehoben werden, sind sie solange von der Löschung
              ausgenommen bis der Vorfall endgültig geklärt ist.
              Reichweitenmessung & Cookies Diese Website verwendet Cookies zur
              pseudonymisierten Reichweitenmessung, die entweder von unserem
              Server oder dem Server Dritter an den Browser des Nutzers Muster
              von datenschutz.org übertragen werden. Bei Cookies handelt es sich
              um kleine Dateien, welche auf Ihrem Endgerät gespeichert werden.
              Ihr Browser greift auf diese Dateien zu. Durch den Einsatz von
              Cookies erhöht sich die Benutzerfreundlichkeit und Sicherheit
              dieser Website. Falls Sie nicht möchten, dass Cookies zur
              Reichweitenmessung auf Ihrem Endgerät gespeichert werden, können
              Sie dem Einsatz dieser Dateien hier widersprechen: -
              Cookie-Deaktivierungsseite der Netzwerkwerbeinitiative:
              http://optout.networkadvertising.org/?c=1#!/ -
              Cookie-Deaktivierungsseite der US-amerikanischen Website:
              http://optout.aboutads.info/?c=2#!/ - Cookie-Deaktivierungsseite
              der europäischen Website:
              http://optout.networkadvertising.org/?c=1#!/ Gängige Browser
              bieten die Einstellungsoption, Cookies nicht zuzulassen. Hinweis:
              Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser
              Website ohne Einschränkungen zugreifen können, wenn Sie
              entsprechende Einstellungen vornehmen. Erfassung und Verarbeitung
              personenbezogener Daten Der Websitebetreiber erhebt, nutzt und
              gibt Ihre personenbezogenen Daten nur dann weiter, wenn dies im
              gesetzlichen Rahmen erlaubt ist oder Sie in die Datenerhebung
              einwilligen. Als personenbezogene Daten gelten sämtliche
              Informationen, welche dazu dienen, Ihre Person zu bestimmen und
              welche zu Ihnen zurückverfolgt werden können – also beispielsweise
              Ihr Name, Ihre E-Mail-Adresse und Telefonnummer. Diese Website
              können Sie auch besuchen, ohne Angaben zu Ihrer Person zu machen.
              Zur Verbesserung unseres Online-Angebotes speichern wir jedoch
              (ohne Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu
              diesen Zugriffsdaten gehören z. B. die von Ihnen angeforderte
              Datei oder der Name Ihres Internet-Providers. Durch die
              Anonymisierung der Daten sind Rückschlüsse auf Ihre Person nicht
              möglich. Hinweis: An dieser Stelle sollten Sie zusätzlich angeben,
              welche, wie und warum Sie als Webseitenbetreiber personenbezogene
              Daten verarbeiten. Beispiele:  Welche? - Wir verarbeiten
              personenbezogene Daten wie Vorname, Nachname, IPAdresse,
              E-Mail-Adresse, Wohnort, Postleitzahl und Inhaltsangaben aus dem
              Kontaktformular.  Wie? - Wir verarbeiten personenbezogene Daten
              nur nach ausdrücklicher Erlaubnis der betreffenden Nutzer und
              unter Einhaltung der geltenden Datenschutzbestimmungen.  Warum? -
              Die Verarbeitung der personenbezogenen Daten erfolgt aufgrund
              unseres berechtigten Interesses zur Erfüllung unserer vertraglich
              vereinbarten Leistungen und zur Optimierung unseres
              Online-Angebotes. Umgang mit Kontaktdaten Nehmen Sie mit uns als
              Websitebetreiber durch die angebotenen Kontaktmöglichkeiten
              Verbindung auf, werden Ihre Angaben gespeichert, damit auf diese
              zur Bearbeitung und Beantwortung Ihrer Anfrage zurückgegriffen
              werden kann. Ohne Ihre Einwilligung werden diese Daten nicht an
              Dritte weitergegeben. Umgang mit Kommentaren und Beiträgen Muster
              von datenschutz.org Hinterlassen Sie auf dieser Website einen
              Beitrag oder Kommentar, wird Ihre IP-Adresse gespeichert. Dies
              erfolgt aufgrund unserer berechtigten Interessen im Sinne des Art.
              6 Abs. 1 lit. f. DSGVO und dient der Sicherheit von uns als
              Websitebetreiber: Denn sollte Ihr Kommentar gegen geltendes Recht
              verstoßen, können wir dafür belangt werden, weshalb wir ein
              Interesse an der Identität des Kommentar- bzw. Beitragsautors
              haben.
            </p>
            <h3 className="font-semibold mt-4"> Google Analytics</h3>
            <p>
              Diese Website nutzt aufgrund unserer berechtigten Interessen zur
              Optimierung und Analyse unseres Online-Angebots im Sinne des Art.
              6 Abs. 1 lit. f. DSGVO den Dienst „Google Analytics“, welcher von
              der Google Inc. (1600 Amphitheatre Parkway Mountain View, CA
              94043, USA) angeboten wird. Der Dienst (Google Analytics)
              verwendet „Cookies“ – Textdateien, welche auf Ihrem Endgerät
              gespeichert werden. Die durch die Cookies gesammelten
              Informationen werden im Regelfall an einen Google-Server in den
              USA gesandt und dort gespeichert. Google LLC hält das europäische
              Datenschutzrecht ein und ist unter dem Privacy-ShieldAbkommen
              zertifiziert:
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
              Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse
              der Nutzer wird innerhalb der Mitgliedsstaaten der EU und des
              Europäischen Wirtschaftsraum und in den anderen Vertragsstaaten
              des Abkommens gekürzt. Nur in Einzelfällen wird die IP-Adresse
              zunächst ungekürzt in die USA an einen Server von Google
              übertragen und dort gekürzt. Durch diese Kürzung entfällt der
              Personenbezug Ihrer IP-Adresse. Die vom Browser übermittelte
              IPAdresse des Nutzers wird nicht mit anderen von Google
              gespeicherten Daten kombiniert. Im Rahmen der Vereinbarung zur
              Auftragsdatenvereinbarung, welche wir als Websitebetreiber mit der
              Google Inc. geschlossen haben, erstellt diese mithilfe der
              gesammelten Informationen eine Auswertung der Websitenutzung und
              der Websiteaktivität und erbringt mit der Internetnutzung
              verbundene Dienstleistungen. Die von Google in unserem Auftrag
              erhobenen Daten werden genutzt, um die Nutzung unseres
              Online-Angebots durch die einzelnen Nutzer auswerten zu können, z.
              B. um Reports über die Aktivität auf der Website zu erstellen, um
              unser Online-Angebot zu verbessern. Sie haben die Möglichkeit, die
              Speicherung der Cookies auf Ihrem Gerät zu verhindern, indem Sie
              in Ihrem Browser entsprechende Einstellungen vornehmen. Es ist
              nicht gewährleistet, dass Sie auf alle Funktionen dieser Website
              ohne Einschränkungen zugreifen können, wenn Ihr Browser keine
              Cookies zulässt. Weiterhin können Sie durch ein Browser-Plugin
              verhindern, dass die durch Cookies gesammelten Informationen
              (inklusive Ihrer IP-Adresse) an die Google Inc. gesendet und von
              der Google Inc. genutzt werden. Folgender Link führt Sie zu dem
              entsprechenden Plugin:
              https://tools.google.com/dlpage/gaoptout?hl=de Alternativ
              verhindern Sie mit einem Klick auf diesen Link (WICHTIG:
              Opt-Out-Link einfügen), dass Google Analytics innerhalb dieser
              Website Daten über Sie erfasst. Mit dem Klick auf obigen Link
              laden Sie ein „Opt-Out-Cookie“ herunter. Ihr Browser muss die
              Speicherung von Cookies also hierzu grundsätzlich erlauben.
              Löschen Sie Ihre Cookies regelmäßig, ist ein erneuter Klick auf
              den Link bei jedem Besuch dieser Website vonnöten. Muster von
              datenschutz.org Hier finden Sie weitere Informationen zur
              Datennutzung durch die Google Inc.: 
              https://policies.google.com/privacy/partners?hl=de (Daten, die von
              GooglePartnern erhoben werden) 
              https://adssettings.google.de/authenticated (Einstellungen über
              Werbung, die Ihnen angezeigt wird) 
              https://policies.google.com/technologies/ads?hl=de (Verwendung von
              Cookies in Anzeigen)
            </p>
            <h3 className="font-semibold mt-4">
              {" "}
              Nutzung von Social-Media-Plugins von Facebook
            </h3>
            <p>
              Aufgrund unseres berechtigten Interesses an der Analyse,
              Optimierung und dem Betrieb unseres Online-Angebotes (im Sinne des
              Art. 6 Abs. 1 lit. f. DSGVO), verwendet diese Website das
              Facebook-Social-Plugin, welches von der Facebook Inc. (1 Hacker
              Way, Menlo Park, California 94025, USA) betrieben wird. Erkennbar
              sind die Einbindungen an dem Facebook-Logo bzw. an den Begriffen
              „Like“, „Gefällt mir“, „Teilen“ in den Farben Facebooks (Blau und
              Weiß). Informationen zu allen Facebook-Plugins finden Sie über den
              folgenden Link: https://developers.facebook.com/docs/plugins/
              Facebook Inc. hält das europäische Datenschutzrecht ein und ist
              unter dem Privacy-ShieldAbkommen zertifiziert:
              https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active
              Das Plugin stellt eine direkte Verbindung zwischen Ihrem Browser
              und den FacebookServern her. Der Websitebetreiber hat keinerlei
              Einfluss auf die Natur und den Umfang der Daten, welche das Plugin
              an die Server der Facebook Inc. übermittelt. Informationen dazu
              finden Sie hier: https://www.facebook.com/help/186325668085084 Das
              Plugin informiert die Facebook Inc. darüber, dass Sie als Nutzer
              diese Website besucht haben. Es besteht hierbei die Möglichkeit,
              dass Ihre IP-Adresse gespeichert wird. Sind Sie während des
              Besuchs auf dieser Website in Ihrem Facebook-Konto eingeloggt,
              werden die genannten Informationen mit diesem verknüpft. Nutzen
              Sie die Funktionen des Plugins – etwa indem Sie einen Beitrag
              teilen oder „liken“ –, werden die entsprechenden Informationen
              ebenfalls an die Facebook Inc. übermittelt. Möchten Sie
              verhindern, dass die Facebook. Inc. diese Daten mit Ihrem
              Facebook-Konto verknüpft, loggen Sie sich bitte vor dem Besuch
              dieser Website bei Facebook aus und löschen Sie die gespeicherten
              Cookies. Über Ihr Facebook-Profil können Sie weitere Einstellungen
              zur Datenverarbeitung für Werbezwecke tätigen oder der Nutzung
              Ihrer Daten für Werbezwecke widersprechen. Zu den Einstellungen
              gelangen Sie hier:  Profileinstellungen bei Facebook:
              https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
              - Cookie-Deaktivierungsseite der US-amerikanischen Website:
              http://optout.aboutads.info/?c=2#!/  Cookie-Deaktivierungsseite
              der europäischen Website:
              http://optout.networkadvertising.org/?c=1#!/ Welche Daten, zu
              welchem Zweck und in welchem Umfang Facebook Daten erhebt, nutzt
              und verarbeitet und welche Rechte sowie Einstellungsmöglichkeiten
              Sie zum Schutz Ihrer Privatsphäre haben, können Sie in den
              Datenschutzrichtlinien von Facebook nachlesen. Diese finden Sie
              hier: https://www.facebook.com/about/privacy/ Muster von
              datenschutz.org
            </p>
            <h3 className="font-semibold mt-4"> Rechte des Nutzers</h3>
            <p>
              Sie haben als Nutzer das Recht, auf Antrag eine kostenlose
              Auskunft darüber zu erhalten, welche personenbezogenen Daten über
              Sie gespeichert wurden. Sie haben außerdem das Recht auf
              Berichtigung falscher Daten und auf die Verarbeitungseinschränkung
              oder Löschung Ihrer personenbezogenen Daten. Falls zutreffend,
              können Sie auch Ihr Recht auf Datenportabilität geltend machen.
              Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet
              wurden, können Sie eine Beschwerde bei der zuständigen
              Aufsichtsbehörde einreichen.
            </p>
            <h3 className="font-semibold mt-4"> Löschung von Daten</h3>
            <p>
              Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur
              Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung) kollidiert,
              haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns
              gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung
              nicht mehr vonnöten sein und es keine gesetzlichen
              Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht
              durchgeführt werden kann, da die Daten für zulässige gesetzliche
              Zwecke erforderlich sind, erfolgt eine Einschränkung der
              Datenverarbeitung. In diesem Fall werden die Daten gesperrt und
              nicht für andere Zwecke verarbeitet.
            </p>
            <h3 className="font-semibold mt-4"> Widerspruchsrecht</h3>
            <p>
              Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch
              machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder
              Zeit widersprechen. Muster von datenschutz.org Wenn Sie eine
              Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer
              Person gespeicherten personenbezogenen Daten wünschen oder Fragen
              bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer
              personenbezogenen Daten haben oder erteilte Einwilligungen
              widerrufen möchten, wenden Sie sich bitte an folgende
              E-Mail-Adresse: info@praxis-am-laegern.ch
            </p>
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default Impressum;
