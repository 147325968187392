import React from "react";
import { Link } from "gatsby";
import cx from "classnames";

const Footer = ({ className, ...props }) => {
  className = cx(
    "w-full px-8 py-4 text-white bg-brand3 flex flex-col md:flex-row justify-between items-start md:items-center",
    className
  );
  return (
    <>
      <div className={className} {...props}>
        <div className="flex-initial text-xl font-semibold">
          Praxis am Lägern
        </div>
        <div>
          <ul className="flex flex-col md:flex-row text-sm -mx-3 font-medium">
            <li className="mx-3">
              <Link to="/">Home</Link>
            </li>
            <li className="mx-3">
              <Link to="/kontakt">Kontakt</Link>
            </li>
            <li className="mx-3">
              <Link to="/impressum">Impressum & Datenschutz</Link>
            </li>
            <li className="mx-3">©2020 Praxis am Lägern</li>
          </ul>
        </div>
      </div>

      <div className="w-full px-8 py-2 text-white text-xs font-light bg-brand flex flex-col sm:flex-row justify-between items-start md:items-center">
        <div>
          <strong className="font-bold">Telefon: </strong>
          <a href="tel:0562210023">056 221 00 23</a>
          <strong className="ml-3 font-bold">Email: </strong>
          <a href="mailto:info@praxis-am-laegern.ch">
            info@praxis-am-laegern.ch
          </a>
          <strong className="ml-3 font-bold">Adresse: </strong>
          <Link to="/kontakt">Kirchweg 20, 5420 Ehrendingen</Link>
        </div>
        <div>
          <Link to="/" className="hover:underline">
            praxis-am-laegern.ch
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;
