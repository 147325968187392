import React, { useState } from "react";
import { Link } from "gatsby";
import cx from "classnames";
import logo from "../images/logo.png";

// const StyledLink = ({ className, ...props }) => {
//   className = cx(className);
//   return (
//     <Link className={className} {...props}>
//       {props.children}
//     </Link>
//   );
// };

const HeaderLink = ({ className, children, ...props }) => {
  className = cx(
    "w-full md:w-auto py-2 md:px-1 -mb-1 text-sm font-medium uppercase hover:text-brand3 border-transparent border-b-4 md:hover:border-brand3 relative",
    className
  );
  return (
    <div className="flex px-1">
      <Link className={className} {...props} activeClassName="md:border-brand">
        {children}
      </Link>
    </div>
  );
};

const Header = ({ className, ...props }) => {
  let [isExpanded, toggleExpansion] = useState(false);

  className = cx("w-full shadow-md", className);
  return (
    <div className={className} {...props}>
      <div className="flex flex-wrap flex-row items-center md:items-center justify-between px-6 md:px-10 lg:px-24 text-brand">
        <div className="my-2 w-3/4 md:w-1/4 lg:w-1/5 xl:w-1/6">
          <Link to="/">
            <img
              className="w-full"
              alt="Praxis am Lägern Logo - Link Home"
              src={logo}
            />
          </Link>
        </div>

        <button
          className="block relative md:hidden my-1 px-3 py-2 focus:outline-none"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current text-brand h-6 w-6"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } w-full md:w-auto flex md:flex flex-col md:flex-row justify-center md:justify-end items-stretch md:items-center py-2`}
        >
          <HeaderLink to="/">Home</HeaderLink>
          <HeaderLink to="/angebot">Angebot</HeaderLink>
          <HeaderLink to="/praxis">Praxis</HeaderLink>
          <HeaderLink to="/referenzen">Referenzen</HeaderLink>
          <HeaderLink to="/kontakt">Kontakt</HeaderLink>
        </div>
      </div>
    </div>
  );
};

export default Header;
